/* You can add global styles to this file, and also import other style files */
@import 'vars';
@import 'mixins';
@import 'bootstrap';
@import '@ng-select/ng-select/themes/default.theme.css';
@import "@ng-select/ng-select/themes/material.theme.css";

@import url('https://css.gg/css');

$fa-font-path: '~@fortawesome/fontawesome-free-webfonts/webfonts';
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


.scroll-target {
  margin-top: -$navbar-height;
  padding-top: $navbar-height;
}

body.modal-open {
  /*
  The browser scrollbar disappears when opening modals (because they disable scrolling). Bootstrap works around this
  by adding an artifical right padding to match the now-missing width. However this breaks when there are
  position: fixed elements with width 100% - they will now appear to be over the "scrollbar".

  Work around Bootstrap's workaround by overriding it.

  NOTE: this means the scrollbar will no longer disappear when showing modals, which means that modals
    will not be scrollable
  */
  padding-right: 0 !important;
  overflow-y: auto;
}

.modal-content {
  border-radius: 0;
}

html {
  height: 100%;

  body {
    font-family: 'Work Sans', sans-serif !important;
    ::-moz-selection { /* Code for Firefox */
      background: rgba(10, 10, 37, 0.57);
      color:white;
    }

    ::selection {
      background: rgba(10, 10, 37, 0.57);
      color:white;
    }
    height: 100%;
    color: $gray-800;
  }
}

.section-title{
  font-weight: 600;
  font-size: 36px;
  line-height: 42px;
  color: $color-primary;

  @media only screen and (max-width: 768px){
    font-size: 28px;
  }

  @media only screen and (max-width: 546px){
    font-size: 22px;
  }
}

.practica-btn {
  border-radius: 8px;
  padding: 10px 20px;
  font-weight: 600;
  transition: 0.25s;
}

.practica-btn:hover, .practica-btn:focus{
  box-shadow: 0 0.5em 0.5em -0.4em rgba(54, 54, 54, 0.12);
  transform: translateY(-0.25em);
}

.practica-btn{
  font-size: 12px;
  border: 0;
}

.practica-colored-wrapper{
  padding: 44px;
  border-radius: 16px;
}

input, textarea, select {
  border: 0;
  border-radius: 8px;
  outline: $color-primary thin;
  padding: 15px;
}
label{
  font-weight: 600;
  color: $gray-400;

  @media only screen and (max-width: 546px) {
    font-size: 14px;
  }
}
.no-padding{
    @media only screen and (min-width: 600px){
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
}

p{
  @media only screen and (max-width: 546px){
    font-size: 13px;
  }
}
.color-primary{
  color: $color-primary;
}

.color-secondary{
  color: $color-secondary;
}
