@import 'helper';

$color-text-white: #F2F2F2;

$color-background-gray: #F6F6F6;
$color-border: #D3D3D3;
$color-error: #BB0000;
$navbar-height: 64px;

$gray-50: #FAFAFA;
$gray-100: #F5F5F5;
$gray-200: #E5E5E5;
$gray-300: #D4D4D4;
$gray-400: #A3A3A3;
$gray-500: #737373;
$gray-600: #525252;
$gray-700: #404040;
$gray-800: #262626;
$gray-900: #171717;

$color-primary: var(--color-primary);
$color-primary-shade: var(--color-primary-shade);
$color-primary-offwhite: var(--color-primary-offwhite);

$color-secondary: var(--color-secondary);
$color-secondary-shade: var(--color-secondary-shade);